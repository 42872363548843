.small-info {
    @include padding-x;
    padding-top: 40px;

    &-text {
        font-size: 20px;
        line-height: 32px;
        opacity: 0.7;

        @media (max-width: 560px) {
            font-size: 14px;
            line-height: 25px;
        }
    }
}

.techno {
    &-box {
        width: 365px;
        height: 355px;
        position: relative;
        overflow: hidden;

        @media (max-width: 1880px) {
            width: 100%;
        }

        @media (max-width: 1680px) {
            height: 320px;
        }

        @media (max-width: 820px) {
            height: 300px;
        }

        &-wrap {
            @include padding-x;
            padding-bottom: 150px;
        }
    }

    &-img {
        position: relative;
        z-index: 1;
        width: 100%;
        overflow: hidden;
        height: 100%;

        img {
            width: 100%;
        }
    }

    &-content {
        &-wrap {
            background: rgba($color: $dark, $alpha: 0.8);
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding: 40px;

            @media (max-width: 1680px) {
                padding: 30px;
            }
        }
    }

    &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: $white;

        @media (max-width: 1800px) {
            font-size: 22px;
        }

        @media (max-width: 1680px) {
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
        }
    }

    &-desc {
        margin-top: 10px;
        margin-bottom: 30px;
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        color: rgba($color: $white, $alpha: 0.7);
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media (max-width: 1680px) {
            font-size: 14px;
            line-height: 24px;
        }

        @media (max-width: 1440px) {
            margin-bottom: 20px;
        }
    }
}

.techno-icon-btn {
    width: 50px;
    height: 50px;
    border: 1px solid $secondary;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-items: center;
    font-size: 45px;
    font-weight: 100;
    color: $white;
    align-self: flex-end;
    text-align: center;
    position: relative;
    cursor: pointer;
    transition: all .3s ease;

    @media (max-width: 1680px) {
        width: 40px;
        height: 40px;
        font-size: 40px;
    }

    &:hover {
        background: $secondary;
    }

    &::before {
        content: '+';
        position: absolute;
        left: 12px;

        @media (max-width: 1680px) {
            left: 9px;
            top: 7px;
        }
    }
}

.detail-info {
    &-wrap {
        @include padding-x;
        padding-top: 60px;
        padding-bottom: 100px;
    }

    &-text {
        font-size: 24px;
        line-height: 42px;
        opacity: 0.7;

        @media (max-width: 1800px) {
            font-size: 22px;
            line-height: 40px;
        }

        @media (max-width: 1440px) {
            font-size: 20px;
            line-height: 38px;
        }

        @media (max-width: 992px) {
            font-size: 16px;
            line-height: 34px;
        }
    }
}

.detail-bottom-banner {
    @include padding-x;
    padding-top: 47px;
    padding-bottom: 47px;
    background: url(../img/greenHydroImages/inner-banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 992px) {
        background-position: left;
    }
}

.detail-banner-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 64px;
    color: $white;

    @media (max-width: 1440px) {
        font-size: 36px;
        line-height: 58px;
    }
}

.plant-info-img {
    @media (max-width: 1440px) {
        width: 100%;
    }
}