.footer {
  &-wrap {
    background: #141c25;
    position: relative;
    @include padding-x-140;
    padding-top: 210px;
    margin-top: 170px;

    @media (max-width: 820px) {
      padding-inline: 65px;
    }

    @media (max-width: 560px) {
      padding-inline: 40px;
    }

    @media (max-width: 620px) {
      margin-top: 50px;
      padding-top: 75px;
    }
  }

  &-menu {
    @media (max-width: 560px) {
      display: grid;
      row-gap: 15px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 20px;
    }
  }

  &-logo {
    padding-right: 40px;
    border-right: 1px solid $primary;
    margin-right: 20px;
  }

  &-link {
    padding: 0 20px;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: $white;
    transition: all 0.3s ease;

    @media (max-width: 820px) {
      padding: 0 15px;
    }
    @media (max-width: 458px) {
      padding: 0 6px;
    }

    &:hover {
      color: $secondary;
    }
  }

  &-top {
    padding-bottom: 50px;

    @media (max-width: 820px) {
      padding-top: 35px;
      padding-bottom: 25px;
    }

    @media (max-width: 560px) {
      flex-direction: column;
    }
  }

  &-bottom {
    border-top: 1px solid $primary;
    padding-top: 50px;
    padding-bottom: 53px;

    @media (max-width: 820px) {
      padding-top: 30px;
      padding-bottom: 40px;
    }
  }
}

.copyright {
  font-size: 11px;
  line-height: 14px;
  color: rgba($color: $white, $alpha: 0.5);
}

.social {
  &-link {
    padding: 0 9px;
    color: $secondary;
    transition: all 0.3s ease;

    &:hover {
      color: $primary;
    }
  }

  &-wrap {
    margin-top: 20px;

    @media (max-width: 620px) {
      margin-top: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.footer2 {
  top: -22%;
  width: 100%;
  position: absolute;
  left: 0;
  @include padding-x-140;

  @media (max-width: 1440px) {
    top: -16%;
  }

  @media (max-width: 1024px) {
    top: -11%;
  }

  @media (max-width: 620px) {
    top: -5%;
  }

  .wrap {
    margin: auto;
  }
}
.social-wrap {
  display: flex;
  // align-items: center;
  flex-direction: row;

  .footer-link {
    @media (max-width: 460px) {
      margin-top: 8px;
    }
  }

  .social-link {
    @media (max-width: 460px) {
      margin-top: 8px;
    }
  }

  @media (max-width: 460px) {
    flex-direction: column;
  }
}
